import { gracely } from "gracely"
import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { Client } from "../Client"

export class Delegations extends smoothly.StateBase<Delegations, Client> {
	public request?: Promise<Delegations["value"]>
	private set organization(organization: State.Organizations["current"]) {
		if (organization != undefined && this.value != undefined)
			this.fetch()
		else if (organization == undefined)
			this.listenable.value = undefined
	}
	private set key(key: State.Me["key"]) {
		if (key != undefined && this.value != undefined)
			this.fetch()
		else if (key == undefined)
			this.listenable.value = undefined
	}
	#value?: Delegations["value"]
	get value(): proquse.Delegation[] | false | undefined {
		return this.#value ?? (this.fetch(), undefined)
	}
	set value(value: Delegations["value"]) {
		this.#value = value
	}
	private constructor(client: Client, private state: { userwidgets: State }) {
		super(client)
	}
	async fetch(): Promise<proquse.Delegation[] | false> {
		const promise = !this.state.userwidgets.me.key
			? undefined
			: !this.state.userwidgets.organizations.current
			? undefined
			: (this.request ??= this.client.delegation
					.list(this.state.userwidgets.organizations.current.id, this.state.userwidgets.me.key.email)
					.then(response => (!proquse.Delegation.type.array().is(response) ? false : response)))
		const result = await promise
		this.request = undefined
		if (this.#value != result)
			this.listenable.value = result
		return result || false
	}

	async create(
		delegation: proquse.Delegation.Creatable,
		parent?: string
	): Promise<proquse.Delegation | gracely.Error | undefined> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.delegation.create(this.state.userwidgets.organizations.current.id, delegation, parent)
		if (proquse.Delegation.is(result))
			this.fetch()
		return result
	}
	async change(delegation: proquse.Delegation): Promise<proquse.Delegation | gracely.Error | undefined> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.delegation.change(this.state.userwidgets.organizations.current.id, delegation)
		if (proquse.Delegation.is(result))
			this.fetch()
		return result
	}
	async remove(delegation: proquse.Delegation): Promise<proquse.Delegation | gracely.Error | undefined> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.delegation.delete(this.state.userwidgets.organizations.current.id, delegation)
		if (proquse.Delegation.is(result))
			this.fetch()
		return result
	}
	static create(client: Client, userwidgets: State): smoothly.WithListenable<Delegations> {
		const backend = new this(client, { userwidgets })
		const listenable = smoothly.Listenable.load(backend)
		userwidgets.me.listen("key", key => (backend.key = key))
		userwidgets.organizations.listen("current", organization => (backend.organization = organization))
		return listenable
	}
}
