import { smoothly } from "smoothly"
import { State } from "@userwidgets/ui"
import { Client } from "../../Client"
import { Expenses } from "./Expenses"
import { Receipts } from "./Receipts"

export class Reports extends smoothly.StateBase<Reports, Client> {
	readonly expenses: smoothly.WithListenable<Expenses>
	readonly receipts: smoothly.WithListenable<Receipts>
	private constructor(client: Client, private state: { userwidgets: State }) {
		super(client)
		this.receipts = Receipts.create(this.client, this.state.userwidgets)
		this.expenses = Expenses.create(this.client, this.state.userwidgets)
	}

	static create(client: Client, userwidgets: State): smoothly.WithListenable<Reports> {
		const backend = new this(client, { userwidgets })
		const listenable = smoothly.Listenable.load(backend)
		return listenable
	}
}
