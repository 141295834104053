import { gracely } from "gracely"
import { isoly } from "isoly"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { rest } from "cloudly-rest"

export class Receipt extends rest.Collection<gracely.Error> {
	async create(
		organization: userwidgets.Organization["id"],
		receipt: proquse.Report.Receipt.Creatable,
		dateRange?: isoly.DateRange
	): Promise<File | gracely.Result | undefined> {
		const url = dateRange ? `/report/receipt?startDate=${dateRange.start}&endDate=${dateRange.end}` : `/report/receipt`
		return await this.client
			.post<{ file: File } | "">(url, receipt, {
				organization,
			})
			.then(response =>
				proquse.Report.Receipt.is(response)
					? response.file
					: response == ""
					? gracely.success.noContent()
					: gracely.Result.is(response)
					? response
					: undefined
			)
	}
	async fetch(
		organization: userwidgets.Organization["id"],
		receipt: proquse.Report.Receipt.Creatable,
		dateRange?: isoly.DateRange
	): Promise<proquse.Report.Receipt.Preview | gracely.Result | undefined> {
		const parameter = receipt.costCenters.map(id => `costCenter=` + id).join("&")
		const url =
			(dateRange ? `/report/receipt?startDate=${dateRange.start}&endDate=${dateRange.end}&` : `/report/receipt?`) +
			parameter

		return await this.client
			.get<proquse.Report.Receipt.Preview | "">(url, {
				organization,
			})
			.then(response =>
				response == ""
					? gracely.success.noContent()
					: proquse.Report.Receipt.Preview.is(response) || gracely.Result.is(response)
					? response
					: undefined
			)
	}
}
