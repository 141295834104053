import { gracely } from "gracely"
import { isoly } from "isoly"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { rest } from "cloudly-rest"

export class Expense extends rest.Collection<gracely.Error> {
	async create(
		organization: userwidgets.Organization["id"],
		expense: proquse.Report.Expense.Creatable,
		dateRange?: isoly.DateRange
	): Promise<File | gracely.Result | undefined> {
		const url = dateRange ? `/report/expense?startDate=${dateRange.start}&endDate=${dateRange.end}` : `/report/expense`
		return await this.client
			.post<{ file: File } | "">(url, expense, {
				organization,
			})
			.then(response =>
				proquse.Report.Expense.is(response)
					? response.file
					: response == ""
					? gracely.success.noContent()
					: gracely.Error.is(response)
					? response
					: undefined
			)
	}

	async fetch(
		organization: userwidgets.Organization["id"],
		expense: proquse.Report.Expense.Creatable,
		dateRange?: isoly.DateRange
	): Promise<proquse.Report.Expense.Preview | gracely.Result | undefined> {
		const idParameter = expense.costCenters.map(id => `costCenter=` + id).join("&")
		const emailParameter = expense.emails ? "&" + expense.emails.map(email => "email=" + email).join("&") : ""
		const url =
			(dateRange ? `/report/expense?startDate=${dateRange.start}&endDate=${dateRange.end}&` : `/report/expense?`) +
			idParameter +
			emailParameter
		return await this.client
			.get<proquse.Report.Expense.Preview | "">(url, {
				organization,
			})
			.then(response =>
				response == ""
					? gracely.success.noContent()
					: gracely.Error.is(response) || proquse.Report.Expense.Preview.is(response)
					? response
					: undefined
			)
	}
}
