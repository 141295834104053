import { gracely } from "gracely"
import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { Client } from "../../Client"
import { Delegations } from "../Delegations"
import { Reports } from "../Reports"

export class Reimbursements extends smoothly.StateBase<Reimbursements, Client> {
	private constructor(
		client: Client,
		private state: {
			delegations: smoothly.WithListenable<Delegations>
			userwidgets: State
			reports: smoothly.WithListenable<Reports>
		}
	) {
		super(client)
	}

	async create(
		purchase: proquse.Purchase,
		receipt: proquse.Receipt["id"]
	): Promise<proquse.Purchase | gracely.Error | false> {
		const result = !this.state.userwidgets.me.key
			? undefined
			: !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.purchase.reimbursement.create(
					this.state.userwidgets.organizations.current.id,
					{ issuer: this.state.userwidgets.me.key.email },
					purchase,
					receipt
			  )
		if (result)
			await Promise.all([this.state.delegations.fetch(), this.state.reports.expenses.fetch()])

		return result || false
	}

	async remove(
		purchase: proquse.Purchase,
		receipt: proquse.Receipt["id"]
	): Promise<proquse.Purchase | gracely.Error | false> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.purchase.reimbursement.remove(
					this.state.userwidgets.organizations.current.id,
					purchase,
					receipt
			  )

		if (result)
			await Promise.all([this.state.delegations.fetch(), this.state.reports.expenses.fetch()])
		return result || false
	}

	static create(
		client: Client,
		delegations: smoothly.WithListenable<Delegations>,
		userwidgets: State,
		reports: smoothly.WithListenable<Reports>
	) {
		const backend = new this(client, { delegations, userwidgets, reports })
		const listenable = smoothly.Listenable.load(backend)
		return listenable
	}
}
