import { gracely } from "gracely"
import { isoly } from "isoly"
import { proquse } from "@proquse/model"
import { rest } from "cloudly-rest"

export class Transaction extends rest.Collection<gracely.Error> {
	async list(
		organization: string,
		dateRange: isoly.DateRange
	): Promise<proquse.Transaction[] | gracely.Error | undefined> {
		return await this.client
			.get<proquse.Transaction[]>(`/transaction?start=${dateRange.start}&end=${dateRange.end}`, {
				organization,
			})
			.then(response =>
				gracely.Error.is(response) || proquse.Transaction.type.array().is(response) ? response : undefined
			)
	}
}
