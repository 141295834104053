import { gracely } from "gracely"
import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { Client } from "../Client"
import { Delegations } from "./Delegations"

export class Receipts extends smoothly.StateBase<Receipts, Client> {
	private constructor(
		client: Client,
		private state: {
			delegations: smoothly.WithListenable<Delegations>
			userwidgets: State
		}
	) {
		super(client)
	}
	async create(
		receipt: proquse.Receipt.Creatable,
		purchase: string,
		organizationId?: string
	): Promise<proquse.Receipt | gracely.Error | undefined> {
		const organization = organizationId
			? organizationId
			: this.state.userwidgets.organizations.current
			? this.state.userwidgets.organizations.current.id
			: undefined
		const result = !organization ? undefined : await this.client.receipt.create(organization, purchase, receipt)
		if (proquse.Receipt.is(result))
			this.state.delegations.fetch()
		return result
	}

	async download(purchaseId: string, receiptId: string): Promise<File | gracely.Error | undefined> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.receipt
					.download(this.state.userwidgets.organizations.current.id, purchaseId, receiptId)
					.then(response => (response instanceof File ? response : undefined))
		return result
	}

	async remove(receipt: proquse.Receipt): Promise<proquse.Receipt | gracely.Error | undefined> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.receipt.remove(this.state.userwidgets.organizations.current.id, receipt)

		if (proquse.Receipt.is(result))
			this.state.delegations.fetch()
		return result
	}

	static create(
		client: Client,
		delegations: smoothly.WithListenable<Delegations>,
		userwidgets: State
	): smoothly.WithListenable<Receipts> {
		const backend = new this(client, { delegations, userwidgets })
		const listenable = smoothly.Listenable.load(backend)
		return listenable
	}
}
