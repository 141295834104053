import { gracely } from "gracely"
import { isoly } from "isoly"
import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { Client } from "../../Client"

export class Expenses extends smoothly.StateBase<Expenses, Client> {
	#request?: Promise<Expenses["preview"]>
	#preview?: Expenses["preview"]
	get preview(): proquse.Report.Expense.Preview | false | { status: 204 } | undefined {
		return this.#preview ?? undefined
	}
	set preview(preview: Expenses["preview"]) {
		this.#preview = preview
	}
	private set organization(organization: State.Organizations["current"]) {
		if (organization != undefined && this.#preview != undefined)
			this.fetch()
		else if (organization != undefined)
			this.listenable.preview = undefined
	}
	#creatable?: Expenses["creatable"]
	get creatable(): proquse.Report.Expense.Creatable | undefined {
		return this.#creatable ?? undefined
	}
	set creatable(creatable: Expenses["creatable"]) {
		this.#creatable = creatable
	}
	#dateRange?: Expenses["dateRange"]
	get dateRange(): isoly.DateRange | undefined {
		return this.#dateRange ?? undefined
	}
	set dateRange(dateRange: Expenses["dateRange"]) {
		this.#dateRange = dateRange
	}
	private constructor(client: Client, private state: { userwidgets: State }) {
		super(client)
	}
	async create(): Promise<File | gracely.Result | false> {
		const result = !this.listenable.creatable
			? false
			: !this.state.userwidgets.organizations.current
			? false
			: await this.client.report.expense
					.create(this.state.userwidgets.organizations.current.id, this.listenable.creatable, this.listenable.dateRange)
					.then(response =>
						response instanceof File ? response : gracely.success.noContent.is(response) ? response : false
					)
		return result || false
	}
	async fetch(): Promise<proquse.Report.Expense.Preview | gracely.Result | false> {
		const promise = !this.listenable.creatable
			? false
			: !this.state.userwidgets.organizations.current
			? false
			: (this.#request ??= this.client.report.expense
					.fetch(this.state.userwidgets.organizations.current.id, this.listenable.creatable, this.listenable.dateRange)
					.then(response =>
						proquse.Report.Expense.Preview.is(response)
							? response
							: gracely.success.noContent.is(response)
							? response
							: false
					))
		const result = await promise
		this.#request = undefined
		if (proquse.Report.Expense.Preview.is(result))
			this.listenable.preview = result
		else
			this.listenable.preview = undefined
		return result || false
	}
	static create(client: Client, userwidgets: State): smoothly.WithListenable<Expenses> {
		const backend = new this(client, { userwidgets })
		const listenable = smoothly.Listenable.load(backend)
		userwidgets.organizations.listen("current", organization => (backend.organization = organization))
		return listenable
	}
}
