import { gracely } from "gracely"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { rest } from "cloudly-rest"

export class Currencies extends rest.Collection<gracely.Error> {
	async list(
		organization: userwidgets.Organization.Identifier
	): Promise<proquse.Payment.Currencies | gracely.Error | undefined> {
		return await this.client
			.get(`/purchase/payment/currency`, { organization })
			.then(response => (gracely.Error.is(response) || proquse.Payment.Currencies.is(response) ? response : undefined))
	}
}
