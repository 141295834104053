import { gracely } from "gracely"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { rest } from "cloudly-rest"

export class Reimbursement extends rest.Collection<gracely.Error> {
	async create(
		organization: userwidgets.Organization["id"],
		creatable: proquse.Payment.Expense.Reimbursement.Creatable,
		purchase: proquse.Purchase,
		receipt: proquse.Receipt["id"]
	): Promise<proquse.Purchase | gracely.Error | undefined> {
		return await this.client
			.put<proquse.Purchase>(`/purchase/${purchase.id}/reimbursement/${receipt}`, creatable, {
				ifMatch: [purchase.modified],
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.Purchase.is(response) ? response : undefined))
	}
	async remove(
		organization: userwidgets.Organization["id"],
		purchase: proquse.Purchase,
		receipt: proquse.Receipt["id"]
	): Promise<proquse.Purchase | gracely.Error | undefined> {
		return await this.client
			.delete<proquse.Purchase>(`/purchase/${purchase.id}/reimbursement/${receipt}`, {
				ifMatch: [purchase.modified],
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.Purchase.is(response) ? response : undefined))
	}
}
