import { smoothly } from "smoothly"
import * as pax2pay from "@pax2pay/model-cde"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { State as UserwidgetsState } from "@userwidgets/ui"
import { Client, client } from "../Client"
import { CostCenters } from "./CostCenters"
import { Currencies } from "./Currencies"
import { Delegations } from "./Delegations"
import { Purchases } from "./Purchases"
import { Receipts } from "./Receipts"
import { Reports } from "./Reports"
import { Warnings } from "./Warnings"

export class State extends smoothly.StateBase<State, Client> {
	readonly userwidgets = UserwidgetsState.create(this.client.userwidgets)
	readonly currencies = Currencies.create(this.client, this.userwidgets)
	readonly delegations = Delegations.create(this.client, this.userwidgets)
	readonly costCenters = CostCenters.create(this.client, this.delegations, this.userwidgets)
	readonly receipts = Receipts.create(this.client, this.delegations, this.userwidgets)
	readonly reports = Reports.create(this.client, this.userwidgets)
	readonly purchases = Purchases.create(this.client, this.delegations, this.userwidgets, this.reports)
	readonly warnings = Warnings.create(this.userwidgets, this.delegations, this.costCenters)
	static create(client: Client): smoothly.WithListenable<State> {
		const state = smoothly.Listenable.load(new this(client))
		state.userwidgets.roles.value = [
			{ label: "Admin", permissions: () => proquse.Roles.record.admin.map(flag => `*.${flag}`).join(" ") },
			{
				label: "Financial Controller",
				permissions: id => proquse.Roles.record.financialController.map(flag => `${id}.${flag}`).join(" "),
			},
			{ label: "User", permissions: id => proquse.Roles.record.user.map(flag => `${id}.${flag}`).join(" ") },
		]
		return state
	}
}
export const state = State.create(client)
Object.assign(globalThis, { state })
Object.assign(globalThis, { pax2pay })
Object.assign(globalThis, { proquse })
Object.assign(globalThis, { userwidgets })
