import { gracely } from "gracely"
import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { Client } from "../../Client"
import { Delegations } from "../Delegations"
import { Reports } from "../Reports"
import { Reimbursements } from "./Reimbursements"

export class Purchases extends smoothly.StateBase<Purchases, Client> {
	readonly reimbursements: smoothly.WithListenable<Reimbursements>
	private constructor(
		client: Client,
		private state: {
			delegations: smoothly.WithListenable<Delegations>
			userwidgets: State
			reports: smoothly.WithListenable<Reports>
		}
	) {
		super(client)
		this.reimbursements = Reimbursements.create(
			this.client,
			this.state.delegations,
			this.state.userwidgets,
			this.state.reports
		)
	}
	async create(
		purchase: proquse.Purchase.Creatable,
		delegation: string
	): Promise<proquse.Purchase | gracely.Error | undefined> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.purchase.create(this.state.userwidgets.organizations.current.id, purchase, delegation)
		if (proquse.Purchase.is(result))
			this.state.delegations.fetch()
		return result
	}
	async change(purchase: proquse.Purchase): Promise<proquse.Purchase | gracely.Error | undefined> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.purchase.change(this.state.userwidgets.organizations.current.id, purchase)

		if (proquse.Purchase.is(result))
			this.state.delegations.fetch()
		return result
	}

	async remove(purchase: proquse.Purchase): Promise<proquse.Purchase | gracely.Error | undefined> {
		const result = !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.purchase.remove(this.state.userwidgets.organizations.current.id, purchase)

		if (proquse.Purchase.is(result))
			this.state.delegations.fetch()
		return result
	}

	static create(
		client: Client,
		delegations: smoothly.WithListenable<Delegations>,
		userwidgets: State,
		reports: smoothly.WithListenable<Reports>
	) {
		const backend = new this(client, { delegations, userwidgets, reports })
		const listenable = smoothly.Listenable.load(backend)
		return listenable
	}
}
