import { gracely } from "gracely"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { rest } from "cloudly-rest"
import { Reimbursement } from "./Reimbursement"

export class Purchase extends rest.Collection<gracely.Error> {
	readonly reimbursement = new Reimbursement(this.client)
	async create(
		organization: userwidgets.Organization["id"],
		purchase: proquse.Purchase.Creatable,
		delegation: proquse.Delegation["id"]
	): Promise<proquse.Purchase | gracely.Error | undefined> {
		return await this.client
			.post<proquse.Purchase>(`/purchase/${delegation}`, purchase, {
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.Purchase.is(response) ? response : undefined))
	}

	async change(
		organization: userwidgets.Organization.Identifier,
		purchase: proquse.Purchase
	): Promise<proquse.Purchase | gracely.Error | undefined> {
		return await this.client
			.put<proquse.Purchase>(`/purchase/${purchase.id}`, purchase, {
				ifMatch: [purchase.modified],
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.Purchase.is(response) ? response : undefined))
	}

	async remove(
		organization: userwidgets.Organization.Identifier,
		purchase: proquse.Purchase
	): Promise<proquse.Purchase | gracely.Error | undefined> {
		return await this.client
			.delete<proquse.Purchase>(`/purchase/${purchase.id}`, {
				ifMatch: [purchase.modified],
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.Purchase.is(response) ? response : undefined))
	}
}
