import { gracely } from "gracely"
import { isoly } from "isoly"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { rest } from "cloudly-rest"

export class CostCenter extends rest.Collection<gracely.Error> {
	async list(
		organization: userwidgets.Organization.Identifier,
		dateRange?: isoly.DateRange
	): Promise<proquse.CostCenter[] | gracely.Error | undefined> {
		return this.client
			.get<proquse.CostCenter[]>(
				`/costCenter?${dateRange ? `startDate=${dateRange.start}&endDate=${dateRange.end}` : ""}`,
				{ organization }
			)
			.then(response =>
				gracely.Error.is(response) || proquse.CostCenter.type.array().is(response) ? response : undefined
			)
	}
	async create(
		organization: userwidgets.Organization.Identifier,
		costCenter: proquse.CostCenter.Creatable,
		parent?: proquse.CostCenter.Identifier
	): Promise<proquse.CostCenter | gracely.Error> {
		return this.client.post<proquse.CostCenter>(`/costCenter/${parent ? parent : ""}`, costCenter, { organization })
	}
	async change(
		organization: userwidgets.Organization.Identifier,
		costCenter: proquse.CostCenter
	): Promise<proquse.CostCenter | gracely.Error | undefined> {
		return this.client
			.put<proquse.CostCenter>(`/costCenter/${costCenter.id}`, costCenter, {
				ifMatch: [costCenter.modified],
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.CostCenter.is(response) ? response : undefined))
	}
	async remove(
		organization: userwidgets.Organization.Identifier,
		costCenter: proquse.CostCenter
	): Promise<proquse.CostCenter | gracely.Error | undefined> {
		return await this.client
			.delete(`/costCenter/${costCenter.id}`, { ifMatch: [costCenter.modified], organization })
			.then(result => (proquse.CostCenter.is(result) || gracely.Error.is(result) ? result : undefined))
	}
}
