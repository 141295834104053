import { gracely } from "gracely"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { rest } from "cloudly-rest"

export class Delegation extends rest.Collection<gracely.Error> {
	async create(
		organization: userwidgets.Organization.Identifier,
		delegation: proquse.Delegation.Creatable,
		parent?: proquse.Delegation["id"] | proquse.CostCenter["id"]
	): Promise<proquse.Delegation | gracely.Error | undefined> {
		return await this.client
			.post<{ delegation: proquse.Delegation }>(
				`/delegation/${[parent].filter(v => v).join("/")}?url=${window.origin}`,
				delegation,
				{ organization }
			)
			.then(response =>
				gracely.Error.is(response)
					? response
					: proquse.Delegation.is(response?.delegation)
					? response.delegation
					: undefined
			)
	}
	async list(
		organization: userwidgets.Organization.Identifier,
		email: userwidgets.User["email"]
	): Promise<proquse.Delegation[] | gracely.Error | undefined> {
		return await this.client
			.get<proquse.Delegation[]>(`/delegation?email=${email}`, { organization })
			.then(response =>
				gracely.Error.is(response) || proquse.Delegation.type.array().is(response) ? response : undefined
			)
	}
	async change(
		organization: userwidgets.Organization.Identifier,
		delegation: proquse.Delegation
	): Promise<proquse.Delegation | gracely.Error | undefined> {
		return await this.client
			.put<proquse.Delegation>(`/delegation/${delegation.id}`, delegation, {
				ifMatch: [delegation.modified],
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.Delegation.is(response) ? response : undefined))
	}
	async delete(
		organization: userwidgets.Organization.Identifier,
		delegation: proquse.Delegation
	): Promise<proquse.Delegation | gracely.Error | undefined> {
		return await this.client
			.delete<proquse.Delegation>(`/delegation/${delegation.id}`, {
				ifMatch: [delegation.modified],
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.Delegation.is(response) ? response : undefined))
	}
}
