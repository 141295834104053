import { isoly } from "isoly"
import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { CostCenters } from "./CostCenters"
import { Delegations } from "./Delegations"

export class Warnings extends smoothly.StateBase<Warnings> {
	private readonly sources: {
		costCenter: proquse.Warning.Record
		delegation: proquse.Warning.Record
	} = {
		costCenter: {},
		delegation: {},
	}
	#value: Warnings["value"] = {}
	get value(): proquse.Warning.Record {
		return this.#value
	}
	private set value(value: Warnings["value"]) {
		this.#value = value
	}
	private constructor() {
		super()
	}
	private readonly subscriptions = {
		key: (key: State.Me["key"]) => {
			if (key == undefined) {
				this.sources.costCenter = {}
				this.sources.delegation = {}
				this.listenable.value = {}
			}
		},
		costCenters: {
			value: (costCenters: CostCenters["value"]) => {
				const now = isoly.Date.now()
				const warnings = (this.sources.costCenter = (costCenters || []).reduce<Warnings["sources"]["costCenter"]>(
					(result, costCenter) =>
						costCenter.id in result ? result : { ...result, ...proquse.CostCenter.warnings(costCenter, now) },
					{}
				))
				this.listenable.value = { ...this.sources.delegation, ...warnings }
			},
			request: (request: CostCenters["request"]) => {
				if (!request) {
					this.sources.costCenter = {}
					this.value = { ...this.sources.delegation }
				}
			},
		},
		delegations: {
			value: (delegations: Delegations["value"]) => {
				const now = isoly.Date.now()
				const warnings = (this.sources.delegation = (delegations || []).reduce<Warnings["sources"]["delegation"]>(
					(result, delegation) =>
						delegation.id in result ? result : { ...result, ...proquse.Delegation.warnings(delegation, now) },
					{}
				))
				this.listenable.value = { ...warnings, ...this.sources.costCenter }
			},
			request: (request: Delegations["request"]) => {
				if (!request) {
					this.sources.delegation = {}
					this.value = { ...this.sources.costCenter }
				}
			},
		},
	}
	static create(
		userwidgets: State,
		delegations: smoothly.WithListenable<Delegations>,
		costCenters: smoothly.WithListenable<CostCenters>
	): smoothly.WithListenable<Warnings> {
		const backend = new this()
		const listenable = smoothly.Listenable.load(backend)
		userwidgets.me.listen("key", backend.subscriptions.key, { lazy: true })
		costCenters.listen("value", backend.subscriptions.costCenters.value, { lazy: true })
		costCenters.listen("request", backend.subscriptions.costCenters.request, { lazy: true })
		delegations.listen("value", backend.subscriptions.delegations.value, { lazy: true })
		delegations.listen("request", () => backend.subscriptions.delegations.request, { lazy: true })
		return listenable
	}
}
