import { gracely } from "gracely"
import { isoly } from "isoly"
import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { Client } from "../../Client"

export class Receipts extends smoothly.StateBase<Receipts, Client> {
	#request?: Promise<Receipts["preview"]>
	#preview?: Receipts["preview"]
	get preview(): proquse.Report.Receipt.Preview | { status: 204 } | false | undefined {
		return this.#preview ?? undefined
	}
	set preview(preview: Receipts["preview"]) {
		this.#preview = preview
	}
	private set organization(organization: State.Organizations["current"]) {
		if (organization != undefined && this.#preview != undefined)
			this.fetch()
		else if (organization != undefined)
			this.listenable.preview = undefined
	}
	#creatable?: Receipts["creatable"]
	get creatable(): proquse.Report.Receipt.Creatable | undefined {
		return this.#creatable ?? undefined
	}
	set creatable(creatable: Receipts["creatable"]) {
		this.#creatable = creatable
	}
	#dateRange?: Receipts["dateRange"]
	get dateRange(): isoly.DateRange | undefined {
		return this.#dateRange ?? undefined
	}
	set dateRange(dateRange: Receipts["dateRange"]) {
		this.#dateRange = dateRange
	}
	private constructor(client: Client, private state: { userwidgets: State }) {
		super(client)
	}
	async create(): Promise<File | gracely.Result | false> {
		const result = !this.listenable.creatable
			? false
			: !this.state.userwidgets.organizations.current
			? undefined
			: await this.client.report.receipt
					.create(this.state.userwidgets.organizations.current.id, this.listenable.creatable, this.listenable.dateRange)
					.then(response =>
						response instanceof File ? response : gracely.success.noContent.is(response) ? response : false
					)
		return result || false
	}
	async fetch(): Promise<proquse.Report.Receipt.Preview | gracely.Result | false> {
		const promise = !this.listenable.creatable
			? undefined
			: !this.state.userwidgets.organizations.current
			? undefined
			: (this.#request ??= this.client.report.receipt
					.fetch(this.state.userwidgets.organizations.current.id, this.listenable.creatable, this.listenable.dateRange)
					.then(response =>
						proquse.Report.Receipt.Preview.is(response)
							? response
							: gracely.success.noContent.is(response)
							? response
							: false
					))
		const result = await promise

		this.#request = undefined
		if (proquse.Report.Receipt.Preview.is(result))
			this.listenable.preview = result
		else
			this.listenable.preview = undefined
		return result || false
	}
	static create(client: Client, userwidgets: State): smoothly.WithListenable<Receipts> {
		const backend = new this(client, { userwidgets })
		const listenable = smoothly.Listenable.load(backend)
		userwidgets.organizations.listen("current", organization => (backend.organization = organization))
		return listenable
	}
}
