import { gracely } from "gracely"
import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { Client } from "../Client"
import { Delegations } from "./Delegations"

export class CostCenters extends smoothly.StateBase<CostCenters, Client> {
	public request?: Promise<CostCenters["value"]>
	private set delegations(delegations: Delegations["value"]) {
		if (delegations != undefined && this.#value != undefined)
			this.fetch()
	}
	private set organization(organization: State.Organizations["current"]) {
		if (organization != undefined && this.#value != undefined)
			this.fetch()
		else if (organization != undefined)
			this.listenable.value = undefined
	}
	private set key(key: State.Me["key"]) {
		if (key != undefined && this.#value != undefined)
			this.fetch()
		else if (key == undefined)
			this.listenable.value = undefined
	}
	#value?: CostCenters["value"]
	get value(): proquse.CostCenter[] | false | undefined {
		return this.#value ?? (this.fetch(), undefined)
	}
	set value(value: CostCenters["value"]) {
		this.#value = value
	}
	private constructor(
		client: Client,
		private state: {
			delegations: smoothly.WithListenable<Delegations>
			userwidgets: State
		}
	) {
		super(client)
	}
	private authorized(key: State.Me["key"], organization: State.Organizations["current"]): boolean {
		return proquse.Roles.satisfies(
			"financialController",
			(key || {})?.permissions ?? {},
			(organization || {})?.id ?? "*"
		)
	}
	async fetch(): Promise<proquse.CostCenter[] | false> {
		const promise =
			!this.state.userwidgets.organizations.current ||
			!this.state.userwidgets.me.key ||
			!this.authorized(this.state.userwidgets.me.key, this.state.userwidgets.organizations.current)
				? undefined
				: (this.request ??= this.client.costCenter
						.list(this.state.userwidgets.organizations.current.id)
						.then(response => (!proquse.CostCenter.type.array().is(response) ? false : response)))
		const result = await promise
		this.request = undefined
		if (this.#value != result)
			this.listenable.value = result
		return result || false
	}
	async create(
		costCenter: proquse.CostCenter.Creatable,
		parent?: string
	): Promise<proquse.CostCenter | gracely.Error | undefined> {
		const result =
			!this.state.userwidgets.organizations.current ||
			!this.state.userwidgets.me.key ||
			!this.authorized(this.state.userwidgets.me.key, this.state.userwidgets.organizations.current)
				? undefined
				: await this.client.costCenter.create(this.state.userwidgets.organizations.current.id, costCenter, parent)
		if (proquse.CostCenter.is(result)) {
			this.fetch()
			this.state.delegations.fetch()
		}
		return result
	}
	async change(costCenter: proquse.CostCenter): Promise<proquse.CostCenter | gracely.Error | undefined> {
		const result =
			!this.state.userwidgets.organizations.current ||
			!this.state.userwidgets.me.key ||
			!this.authorized(this.state.userwidgets.me.key, this.state.userwidgets.organizations.current)
				? undefined
				: await this.client.costCenter.change(this.state.userwidgets.organizations.current.id, costCenter)
		if (proquse.CostCenter.is(result)) {
			this.fetch()
			this.state.delegations.fetch()
		}
		return result
	}
	async remove(costCenter: proquse.CostCenter): Promise<proquse.CostCenter | gracely.Error | undefined> {
		const result =
			!this.state.userwidgets.me.key ||
			!this.state.userwidgets.organizations.current ||
			!this.authorized(this.state.userwidgets.me.key, this.state.userwidgets.organizations.current)
				? undefined
				: await this.client.costCenter.remove(this.state.userwidgets.organizations.current.id, costCenter)
		if (proquse.CostCenter.is(result)) {
			this.fetch()
			this.state.delegations.fetch()
		}
		return result
	}
	static create(
		client: Client,
		delegations: smoothly.WithListenable<Delegations>,
		userwidgets: State
	): smoothly.WithListenable<CostCenters> {
		const backend = new this(client, { delegations, userwidgets })
		const listenable = smoothly.Listenable.load(backend)
		userwidgets.organizations.listen("current", organization => (backend.organization = organization))
		userwidgets.me.listen("key", key => (backend.key = key))
		delegations.listen("value", async delegations => (backend.delegations = delegations))
		return listenable
	}
}
