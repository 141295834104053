import { gracely } from "gracely"
import { proquse } from "@proquse/model"
import { userwidgets } from "@userwidgets/model"
import { rest } from "cloudly-rest"

export class Receipt extends rest.Collection<gracely.Error> {
	async create(
		organization: userwidgets.Organization.Identifier,
		purchase: proquse.Purchase.Identifier,
		receipt: proquse.Receipt.Creatable
	): Promise<proquse.Receipt | gracely.Error | undefined> {
		return await this.client
			.post<proquse.Receipt>(`/receipt/${purchase}`, receipt, {
				contentType: "multipart/form-data",
				organization,
			})
			.then(response => (gracely.Error.is(response) || proquse.Receipt.is(response) ? response : undefined))
	}

	async download(
		organization: userwidgets.Organization.Identifier,
		purchase: proquse.Purchase.Identifier,
		id: proquse.Receipt.Identifier
	): Promise<File | gracely.Error | undefined> {
		return await this.client
			.get<{ file: File } | gracely.Error>(`/receipt/${purchase}/${id}`, {
				organization,
			})
			.then(response =>
				typeof response == "object" && response && "file" in response && response.file instanceof File
					? response.file
					: gracely.Error.is(response)
					? response
					: undefined
			)
	}

	async remove(
		organization: userwidgets.Organization.Identifier,
		receipt: proquse.Receipt
	): Promise<proquse.Receipt | gracely.Error | undefined> {
		return await this.client
			.delete<proquse.Receipt>(`/receipt/${receipt.id}`, { ifMatch: [proquse.Receipt.uploaded(receipt)], organization })
			.then(response => (proquse.Receipt.is(response) || gracely.Error.is(response) ? response : undefined))
	}
}
